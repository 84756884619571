<template>
  <Dialog v-model:visible="isShow" :style="{width: '450px'}" header="修改密码" :modal="true" class="p-fluid"
          @hide="isShow=false">
    <form @submit.prevent="handleSubmit()" class="p-fluid" style="margin-top: 32px">
      <div class="p-field p-grid">
        <label for="oldPassword" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>原密码:
        </label>
        <div class="p-col">
          <Password id="oldPassword" v-model.trim="form.oldPassword" @change="cancelPasswordError()"/>
          <small class="p-error" v-if="!form.oldPassword && form.$submitted">请输入原密码</small>
          <small class="p-error" v-if="isOldPasswordError && form.$submitted">原密码错误，请检查</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="newPassword1" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>新密码:
        </label>
        <div class="p-col">
          <Password id="newPassword1" v-model.trim="form.newPassword1"/>
          <small class="p-error" v-if="!form.newPassword1 && form.$submitted">请输入新密码&nbsp;&nbsp;&nbsp;&nbsp;</small>
          <small class="p-error" v-if="!new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,25}').test(form.newPassword1) && form.$submitted">不符合密码内容要求</small>
        </div>
        <div style="color: #000000;opacity:0.45;margin-left: 107px;width:calc(100% - 107px)">密码为8-25位字母、数字和特殊字符组合</div>

      </div>
      <div class="p-field p-grid">
        <label for="newPassword2" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>确认密码:
        </label>
        <div class="p-col">
          <Password id="newPassword2" v-model.trim="form.newPassword2"/>
          <small class="p-error" v-if="!form.newPassword2 && form.$submitted">请输入新密码&nbsp;&nbsp;&nbsp;&nbsp;</small>
          <small class="p-error" v-if="form.newPassword1 != form.newPassword2 && form.$submitted">两次密码不一致</small>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button label="取消" class="p-button-outlined" @click="isShow=false"/>
        <Button style="margin: 0" type="submit" label="提交"/>
      </div>
    </form>
  </Dialog>
  <Toast/>
</template>

<script>

var md5 = require('md5');
export default {
    data(){
      return {
        isShow: false,
        isOldPasswordError: false,
        form: {
          oldPassword: null,
          newPassword1: null,
          newPassword2: null,
          $submitted: false
        }
      }
    },
    methods: {
      cancelPasswordError(){
        this.isOldPasswordError = false;
      },
      show() {
        for(let i in this.form){
          this.form[i] = null;
        }
        this.isShow = true;
      },
      handleSubmit() {
        this.form.$submitted = true;
        if(!this.form.oldPassword){
          return;
        }

        if(this.form.newPassword1 != this.form.newPassword2){
          return;
        }

        if(!new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,25}').test(this.form.newPassword1)){
          return;
        }

        // 检查老密码是否正确
        let userObj = JSON.parse(localStorage.getItem('userinfo')).user;
        this.$http("/user/isPasswordRight","get",{
          "telephone" : userObj.telephone,
          "password" : md5(this.form.oldPassword)
        },(resp)=>{
          this.isOldPasswordError = !resp.data.isRight
          if(resp.data.isRight){
            this.$confirm.require({
              header: '确认',
              message: '确认要修改密码吗？',
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: '确认',
              rejectLabel: '取消',
              accept: () => {
                this.$http("/user/resetPassword4User","get",{"telephone":userObj.telephone,"password":md5(this.form.newPassword1)},()=>{
                  this.isShow = false;

                  // 修改localstorage中的密码信息
                  let oldUserObj = JSON.parse(localStorage.getItem("userinfo"));
                  oldUserObj["user"]["password"]=this.form.newPassword1;
                  localStorage.setItem("userinfo", JSON.stringify(oldUserObj));

                  this.$toast.add({severity: 'success', summary: '成功', detail: '密码修改成功！', life: 3000});
                });
              }
            });
          }
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer{
  padding: 0;
}
</style>
